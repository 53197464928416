body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 940px;
  min-width: 1280px;
  width: 100vw;
  height: 100vh;
}
p {
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color:transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #E2E2E2;
  background-clip: padding-box;
  border-radius: 20px;
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Manrope-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/Manrope-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Manrope-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Manrope-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  src: url("./assets/fonts/OpenSans-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: bold;
  font-style: normal;
  src: url("./assets/fonts/OpenSans-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Inter-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/Inter-Medium.ttf") format("opentype");
}